.footer {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  // min-height: 2160px;
  padding-top: 150px;
  padding-bottom: 70px;
  overflow-x: hidden;

  @media (max-width: 3839px) {
    // min-height: 1440px;
    padding-top: 80px;
    padding-bottom: 63px;
  }

  @media (max-width: 2559px) {
    // min-height: 1080px;
    padding-top: 50px;
    padding-bottom: 34px;
  }

  @media (max-width: 1919px) {
    // min-height: 768px;
    padding-top: 30px;
    padding-bottom: 24px;
  }

  @media (max-width: 1365px) {
    // min-height: 1024px;
  }

  @media (max-width: 767px) {
    // min-height: 667px;
    padding-top: 24px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.title {
  font: var(--titleThird);
  color: var(--accentColor);
  z-index: 1;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom {
  z-index: 1;
}

.companyName {
  margin-bottom: 100px;
  font: var(--textSecond);
  color: var(--lightColor);

  @media (max-width: 3839px) {
    margin-bottom: 70px;
  }

  @media (max-width: 2559px) {
    margin-bottom: 56px;
  }

  @media (max-width: 1919px) {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.btns {
  display: flex;
  margin-bottom: 50px;
  gap: 40px;

  @media (max-width: 3839px) {
    gap: 26px;
    margin-bottom: 40px;
  }

  @media (max-width: 2559px) {
    gap: 24px;
    margin-bottom: 32px;
  }

  @media (max-width: 1919px) {
    gap: 16px;
    margin-bottom: 22px;
  }

  @media (max-width: 1365px) {
    gap: 22px;
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 11px;
    margin-bottom: 16px;
  }

  .activeBtn {
    color: #0070eb;
  }

  button {
    padding: 16px 38px 16px 128px;
    color: var(--lightColor);
    font: var(--captionSecond);
    background-color: rgba(250, 250, 250, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 1000px;
    text-transform: uppercase;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 66px 66px;
    background-position: 38px center;
    transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;

    &:nth-child(1) {
      background-image: url("../../../public/content/icons/singapore.svg");
    }
    &:nth-child(2) {
      background-image: url("../../../public/content/icons/hong-kong.svg");
    }
    &:nth-child(3) {
      background-image: url("../../../public/content/icons/china.svg");
    }
    &:nth-child(4) {
      background-image: url("../../../public/content/icons/united-kingdom.svg");
    }
    &:nth-child(5) {
      background-image: url("../../../public/content/icons/united-states.svg");
    }

    @media (min-width: 1024px) {
      &:hover {
        background-color: transparent;
        backdrop-filter: blur(0px);
      }
    }

    @media (max-width: 3839px) {
      padding: 12px 30px 12px 88px;
      background-size: 40px 40px;
      background-position: 30px center;
    }

    @media (max-width: 2559px) {
      padding: 10px 20px 10px 76px;
      background-position: 20px center;
    }

    @media (max-width: 1919px) {
      padding: 6px 12px 6px 44px;
      background-size: 24px 24px;
      background-position: 12px center;
    }

    @media (max-width: 1365px) {
      padding: 4px 10px 4px 42px;
      background-position: 10px center;
    }

    @media (max-width: 1365px) {
      padding: 4px 10px 4px 42px;
      background-position: 10px center;
    }

    @media (max-width: 767px) {
      padding: 3px 8px 3px 34px;
      font-size: 12px;
      line-height: 18px;
      background-size: 18px 18px;
      background-position: 8px center;
    }
  }
}

.address {
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 70px;

  &::after {
    content: "";
    position: absolute;
    left: -100vw;
    bottom: 0;
    width: 200vw;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  }

  @media (max-width: 3839px) {
    padding-bottom: 60px;
    margin-bottom: 60px;
  }

  @media (max-width: 2559px) {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  @media (max-width: 1919px) {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  a {
    white-space: pre-line;
    font: var(--titleSecond);
    color: var(--lightColor);

    @media (max-width: 1365px) {
      white-space: normal;
    }

    @media (max-width: 767px) {
      display: inline-block;
      white-space: normal;
      // width: 95%;
    }
  }
}

.linksBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1365px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.links {
  display: flex;
  gap: 67px;

  @media (max-width: 3839px) {
    gap: 40px;
  }

  @media (max-width: 2559px) {
    gap: 24px;
  }

  @media (max-width: 1365px) {
    width: 100%;
    padding-bottom: 24px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
    padding-bottom: 20px;
  }
}

.link {
  width: max-content;
  padding: 16px 38px;
  font: var(--textSecond);
  color: var(--lightColor);
  background-color: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 1000px;
  transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;

  @media (max-width: 3839px) {
    padding: 14px 26px;
  }

  @media (max-width: 2559px) {
    padding: 10px 20px;
  }

  @media (max-width: 1919px) {
    padding: 10px 16px;
  }

  @media (max-width: 767px) {
    padding: 8px 13px;

    &:nth-child(3) {
      margin-top: 16px;
    }
  }

  &:hover {
    background: transparent;
    backdrop-filter: blur(0px);
  }
}

.firstStudioLink {
  font: var(--textSecond);
  color: var(--lightColor);

  @media (max-width: 1919px) {
  }
}
