.container {
  padding-bottom: 400px;

  @media (max-width: 3839px) {
    padding-bottom: 250px;
  }

  @media (max-width: 2559px) {
    padding-bottom: 170px;
  }

  @media (max-width: 1919px) {
    padding-bottom: 130px;
  }

  @media (max-width: 767px) {
    padding-bottom: 100px;
  }
}

.container__inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #001124;
  border-radius: 60px;
  overflow: hidden;
  padding: 100px 0 100px 100px;

  @media (max-width: 3839px) {
    padding: 60px 0 60px 60px;
  }

  @media (max-width: 2559px) {
    padding: 40px 0 40px 40px;
  }

  @media (max-width: 1919px) {
    border-radius: 30px;
    padding: 30px 0 30px 30px;
  }

  @media (max-width: 1365px) {
    border-radius: 20px;
    padding: 0;
    padding-bottom: 40px;
    flex-direction: column-reverse;
  }

  @media (max-width: 767px) {
    padding: 24px;
  }
}

.container__content {
  height: 100%;
  width: 36%;

  @media (max-width: 1365px) {
    width: 100%;
    padding: 0 24px;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
}

.container__content_block {
  @media (max-width: 1365px) {
    display: flex;
    gap: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

.container__content_name {
  font: var(--titleFifth);
  color: var(--lightColor);
  margin: 0;
  margin-bottom: 332px;

  @media (max-width: 3839px) {
    margin-bottom: 227px;
  }

  @media (max-width: 2559px) {
    margin-bottom: 160px;
  }

  @media (max-width: 1919px) {
    margin-bottom: 90px;
  }

  @media (max-width: 1365px) {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
    text-align: center;
  }
}

.container__content_item {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.container__content_img {
  width: 390px;
  height: 400px;
  object-fit: contain;
  margin-bottom: 100px;

  @media (max-width: 3839px) {
    width: 320px;
    height: 328px;
    margin-bottom: 60px;
  }

  @media (max-width: 2559px) {
    width: 195px;
    height: 200px;
    margin-bottom: 40px;
  }

  @media (max-width: 1919px) {
    width: 166px;
    height: 170px;
    margin-bottom: 30px;
  }

  @media (max-width: 1365px) {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: 117px;
    height: 120px;
  }
}

.container__content_description {
  font-family: "Brawler";
  font-size: 152px;
  font-style: normal;
  font-weight: 400;
  line-height: 117%;
  color: var(--lightColor);
  margin-bottom: 96px;

  @media (max-width: 3839px) {
    font-size: 99px;
    margin-bottom: 72px;
  }

  @media (max-width: 2559px) {
    font-size: 82px;
    margin-bottom: 40px;
  }

  @media (max-width: 1919px) {
    font-size: 66px;
    margin-bottom: 24px;
    width: 570px;
  }

  @media (max-width: 1365px) {
    font-size: 50px;
    width: auto;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 16px;
    text-align: center;
  }
}

.container__content_button {
  width: max-content;
  font: var(--captionSecond);
  color: var(--lightColor);
  text-transform: uppercase;

  display: flex;
  padding: 16px 38px;
  justify-content: center;
  align-items: center;

  border-radius: 1000px;
  background-color: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(10px);
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: transparent;
  }

  @media (max-width: 3839px) {
    padding: 12px 30px;
  }

  @media (max-width: 2559px) {
    padding: 6px 12px;
  }

  @media (max-width: 1365px) {
    padding: 4px 10px;
  }
  @media (max-width: 767px) {
    padding: 3px 8px;
    font: var(--captionThird);
  }
}

.container__video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2220px;
  height: 1700px;
  position: relative;
  overflow: hidden;
  isolation: isolate;

  @media (max-width: 3839px) {
    width: 1436.277px;
    height: 1100px;
  }

  @media (max-width: 2559px) {
    width: 1109.85px;
    height: 850px;
  }

  @media (max-width: 1919px) {
    width: 848.709px;
    height: 650px;
  }

  @media (max-width: 1365px) {
    width: 600px;
    height: 459.521px;
    margin: 47px;
  }

  @media (max-width: 767px) {
    width: 300px;
    height: 229.761px;
    margin: 0 24px 24px 24px;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0%;
    -webkit-transform: translate3d(10%, 0%, 3px);
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../../public/content/images/about/company1b/video-frame.webp");
    z-index: 2;
    isolation: isolate;

    @media (max-width: 1365px) {
      background-image: url("../../../../public/content/images/about/company1b/video-frame--tablet.webp");
      -webkit-transform: translate3d(0%, 0, 2px);
    }

    @media (max-width: 767px) {
      background-image: url("../../../../public/content/images/about/company1b/video-frame--mobile.webp");
    }
  }

  &::before {
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(13%);
    position: absolute;
    width: 95%;
    height: 95%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../public/content/images/about/company1b/poster.jpg");
    z-index: 1;
    -webkit-transform: translate3d(13%, -50%, 1px);
    isolation: isolate;

    @media (max-width: 1365px) {
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 1px);
    }

    @media (max-width: 767px) {
      background-image: url("../../../../public/content/images/about/company1b/poster-mobile.jpg");
    }
  }
}

.container__video_item {
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(13%);
  position: absolute;
  width: 95%;
  height: 95%;
  object-fit: cover;
  z-index: 2;
  -webkit-transform: translate3d(13%, -50%, 2px);
  isolation: isolate;

  @media (max-width: 1365px) {
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 2px);
  }
}
