.screen {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 70px 0;
  z-index: 450;
  pointer-events: none;
  transition: width 0.3s ease-in-out;

  @media (max-width: 3839px) {
    padding: 40px 0;
  }
  @media (max-width: 2559px) {
    padding: 30px 0;
  }
  @media (max-width: 1919px) {
    padding: 20px 0;
  }
  @media (max-width: 1365px) {
    padding: 24px 0;
  }
  @media (max-width: 767px) {
    padding: 0px;
    overflow: hidden;
  }

  &::after {
    @media (max-width: 1365px) {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: calc(var(--vh, 1vh) * 33);
      width: 100%;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.4;
    }
  }

  .header {
    position: static;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 767px) {
      top: 24px;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 14px;
      z-index: 410;
      pointer-events: all;
      padding-top: 0;
    }

    .menuOpenBtn {
      display: none;
      width: 32px;
      height: 32px;

      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 80px;
        backdrop-filter: blur(10px);
        background: rgba(250, 250, 250, 0.1);
        padding: 9px 7px;
        z-index: 10;

        span {
          width: 100%;
          height: 1px;
          border: 0.5px solid var(--lightColor);
          border-radius: 1px;
          background-color: var(--lightColor);
          transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
        }
      }
    }

    img {
      width: 525px;
      height: 130px;
      transition: transform 0.3s ease-in-out;
      pointer-events: all;
      cursor: pointer;

      @media (max-width: 3839px) {
        width: 400px;
        height: 100px;
      }
      @media (max-width: 2559px) {
        width: 242px;
        height: 60px;
      }
      @media (max-width: 1919px) {
        width: 178px;
        height: 44px;
      }
      @media (max-width: 767px) {
        width: 120px;
        height: 30px;
      }
    }
  }

  .headerDisabled {
    @media (max-width: 767px) {
      pointer-events: none;
    }
  }

  nav {
    transition: transform 0.3s ease-in-out;

    @media (max-width: 767px) {
      width: 100vw;
      max-height: calc(var(--vh, 1vh) * 100);
      height: calc(var(--vh, 1vh) * 100);
      background: var(--darkPrimaryColor);
      transform: translateY(100%);
    }

    ul {
      display: flex;
      padding: 24px 64px;
      gap: 64px;
      background: rgba(250, 250, 250, 0.1);
      backdrop-filter: blur(10px);
      border-radius: 80px;

      @media (max-width: 3839px) {
        padding: 24px 40px;
        gap: 40px;
      }

      @media (max-width: 2559px) {
        padding: 16px 24px;
        gap: 30px;
      }

      @media (max-width: 1919px) {
        padding: 10px 16px;
        gap: 16px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-end;
        background-color: transparent;
        backdrop-filter: none;
        border-radius: 0;
        gap: 0;
      }

      li {
        display: block;
        overflow: hidden;

        @media (max-width: 767px) {
          padding: 16px 0;
          border-top: 1px solid rgba(250, 250, 250, 0.1);
        }

        &:hover {
          button,
          a {
            transform: translateY(-100%);

            @media (max-width: 767px) {
              transform: translateY(-200%);
              width: max-content;
            }
          }
          button::after,
          a::after {
            transform: translateY(100%) skewY(0deg);

            @media (max-width: 767px) {
              transform: translateY(200%) skewY(0deg);
            }
          }
        }

        button,
        a {
          position: relative;
          display: inline-block;
          font: var(--titleFifth);
          color: #fff;
          transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
          pointer-events: all;
          cursor: pointer;

          @media (max-width: 767px) {
            font-size: 28px;
            line-height: 38px;
          }

          &::after {
            content: attr(data-text);
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateY(100%) skewY(8deg);
            transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);

            @media (max-width: 767px) {
              transform: translateY(200%) skewY(8deg);
            }
          }
        }
      }

      li:nth-child(6) {
        display: none;

        @media (max-width: 767px) {
          position: relative;
          display: block;

          a {
            font: var(--textSecond);
            color: rgba(250, 250, 250, 0.5);

            span {
              display: inline;
              color: var(--accentColor);
            }
          }
        }
      }
    }
  }
}

.screenActive {
  width: 100vw;
  height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  .menuOpenBtn {
    span:nth-child(1) {
      transform: rotate(45deg) translate(4.5px, 4.5px);
      // transform: rotate(45deg) translate(0, 13px);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotate(-45deg) translate(4.5px, -4.5px);
      // transform: rotate(-45deg) translate(0, -13px);
    }
  }
}
