.hero {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  z-index: -1;
}

.title {
  width: 60%;
  font: var(--titleFirst);
  color: var(--lightColor);
  text-align: center;
  z-index: 1;

  @media (max-width: 1919px) {
    width: 65%;
  }

  @media (max-width: 1365px) {
    width: 90%;
  }

  span {
    color: var(--accentColor);
  }
}

.description {
  // height: calc(var(--vh, 1vh) * 100);
  // max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkPrimaryColor);
  padding: 490px 390px;

  @media (max-width: 3839px) {
    padding: 250px 275px;
  }

  @media (max-width: 2559px) {
    padding: 170px 190px;
  }

  @media (max-width: 1919px) {
    padding: 130px 138px;
  }

  @media (max-width: 1365px) {
    padding: 130px 37px;
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 32px;
    padding: 100px 13px;
  }

  p {
    width: 64%;
    font: var(--textFirst);
    color: var(--lightColor);

    @media (max-width: 3839px) {
      width: 69%;
    }

    @media (max-width: 2559px) {
      width: 61%;
    }

    @media (max-width: 1919px) {
      width: 60%;
    }

    @media (max-width: 1365px) {
      width: 70%;
    }

    @media (max-width: 767px) {
      text-align: center;
      width: 100%;
    }
  }
}

.pools {
  padding-bottom: 490px;

  @media (max-width: 3839px) {
    padding-bottom: 250px;
  }

  @media (max-width: 2559px) {
    padding-bottom: 170px;
  }

  @media (max-width: 1919px) {
    padding-bottom: 130px;
  }

  @media (max-width: 767px) {
    padding-bottom: 100px;
  }

  h2 {
    margin-bottom: 130px;
    font: var(--titleThird);
    color: var(--accentColor);

    @media (max-width: 3839px) {
      margin-bottom: 75px;
    }

    @media (max-width: 2559px) {
      margin-bottom: 50px;
    }

    @media (max-width: 1919px) {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

.poolsArticle {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 3690px;
  max-height: 1513px;
  background: var(--darkSecondaryColor);
  border-radius: 60px;
  overflow: hidden;
  isolation: isolate;
  cursor: none;

  @media (max-width: 3839px) {
    max-width: 2418px;
    max-height: 990px;
    border-radius: 40px;
  }

  @media (max-width: 2559px) {
    max-width: 1854px;
    max-height: 750px;
    border-radius: 30px;
  }

  @media (max-width: 1919px) {
    max-width: 1314px;
    max-height: 550px;
    border-radius: 20px;
  }

  @media (max-width: 1365px) {
    flex-direction: column-reverse;
    max-width: 694px;
    max-height: 815px;
  }

  @media (max-width: 767px) {
    max-width: 348px;
    max-height: 547px;
  }

  &:not(:last-child) {
    margin-bottom: 150px;

    @media (max-width: 3839px) {
      margin-bottom: 80px;
    }

    @media (max-width: 2559px) {
      margin-bottom: 32px;
    }

    @media (max-width: 1365px) {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  &:hover .poolsArticleRight img {
    @media (min-width: 1365px) {
      transform: scale(1.1);
    }
  }
}

.poolsArticleLeft {
  position: relative;
  padding: 100px;

  @media (max-width: 3839px) {
    padding: 60px;
  }

  @media (max-width: 2559px) {
    padding: 40px;
  }

  @media (max-width: 1919px) {
    padding: 30px;
  }

  @media (max-width: 1365px) {
    height: 40%;
    width: 100%;
    padding: 24px;
    padding-bottom: 45px;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }

  button {
    display: none;
    position: absolute;
    font: var(--titleFifth);
    color: #fff;
    background-image: url("../../../public/content/icons/arrow-right-white.svg");
    background-repeat: no-repeat;

    @media (max-width: 1365px) {
      display: block;
      padding: 8px 44px 8px 16px;
      top: 34px;
      right: 24px;
      border: 1px solid var(--lightColor);
      border-radius: 100000px;
      background-position: 90% 50%;
      background-size: 24px 24px;
    }

    @media (max-width: 767px) {
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      padding: 0;
      font-size: 0;
      background-size: 20px 20px;
      background-position: center center;
    }
  }

  h6 {
    margin-bottom: 240px;
    font: var(--titleSecond);
    color: var(--lightColor);

    @media (max-width: 3839px) {
      margin-bottom: 160px;
    }

    @media (max-width: 2559px) {
      margin-bottom: 100px;
    }

    @media (max-width: 1919px) {
      margin-bottom: 86px;
    }

    @media (max-width: 1365px) {
      margin-bottom: 48px;
    }

    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  ul {
    @media (max-width: 1365px) {
      display: flex;
      justify-content: space-around;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: space-between;
    }

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 140px;

        @media (max-width: 3839px) {
          margin-bottom: 80px;
        }

        @media (max-width: 2559px) {
          margin-bottom: 85px;
        }

        @media (max-width: 1919px) {
          margin-bottom: 48px;
        }

        @media (max-width: 1365px) {
          margin-bottom: 0;
        }

        @media (max-width: 767px) {
          margin-bottom: 18px;
        }
      }

      & > div {
        display: flex;
        align-items: center;

        @media (max-width: 1365px) {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        @media (max-width: 767px) {
          flex-direction: row;
          text-align: left;
        }

        & > div:nth-child(1) {
          width: 190px;
          height: 190px;
          background-color: rgba(0, 112, 235, 0.1);
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: 55%;
          background-position: center;
          margin-right: 60px;

          @media (max-width: 3839px) {
            width: 130px;
            height: 130px;
            margin-right: 40px;
          }

          @media (max-width: 2559px) {
            width: 90px;
            height: 90px;
            margin-right: 30px;
          }

          @media (max-width: 1919px) {
            width: 62px;
            height: 62px;
            margin-right: 24px;
          }

          @media (max-width: 1365px) {
            margin-right: 0;
            margin-bottom: 16px;
          }

          @media (max-width: 767px) {
            margin-bottom: 0;
            margin-right: 16px;
            width: 44px;
            height: 44px;
          }
        }

        & > div:nth-child(2) {
          display: block;

          & > div {
            span {
              margin-bottom: 8px;
              font: var(--titleFourth);
              color: var(--accentColor);

              @media (max-width: 3839px) {
                margin-bottom: 2px;
              }
              @media (max-width: 1919px) {
                margin-bottom: 4px;
              }
              @media (max-width: 1365px) {
                margin-bottom: 0;
              }
            }
          }

          p {
            font: var(--captionFirst);
            color: var(--lightColor);
            text-transform: uppercase;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.poolsArticleRight {
  width: 60%;
  overflow: hidden;

  @media (max-width: 1365px) {
    width: 100%;
    height: 60%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.75s ease-in-out;
  }
}

.fleetsContainer {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  // min-height: 2160px;
  display: flex;
  align-items: center;
  padding-right: 485px;
  padding-left: 485px;
  background-color: var(--accentColor);
  overflow: hidden;

  @media (max-width: 3839px) {
    // min-height: 1440px;
    padding-right: 250px;
    padding-left: 250px;
  }

  @media (max-width: 2559px) {
    // min-height: 1080px;
  }

  @media (max-width: 1919px) {
    // min-height: 768px;
    padding-right: 190px;
    padding-left: 190px;
  }

  @media (max-width: 1365px) {
    // min-height: 1024px;
    padding-right: 35px;
    padding-left: 35px;
  }

  @media (max-width: 767px) {
    // min-height: 667px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .fleetTextContent {
    padding-right: 485px;
    padding-left: 485px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // &:last-child {
    //   opacity: 0;
    // }

    @media (max-width: 3839px) {
      padding-right: 250px;
      padding-left: 250px;
    }

    @media (max-width: 1919px) {
      padding-right: 190px;
      padding-left: 190px;
    }

    @media (max-width: 1365px) {
      padding-right: 35px;
      padding-left: 35px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    h2 {
      display: inline-block;
      padding: 164px 78px;
      font: var(--titleThird);
      color: var(--lightColor);
      border: 1px solid var(--lightColor);
      border-radius: 50%;

      @media (max-width: 3839px) {
        padding: 112px 50px;
      }

      @media (max-width: 2559px) {
        padding: 80px 36px;
      }

      @media (max-width: 1919px) {
        padding: 63px 26px;
      }

      @media (max-width: 1365px) {
        padding: 43px 20px;
      }

      @media (max-width: 767px) {
        padding: 43px 30px;
      }
    }

    p {
      width: 69%;
      font: var(--textFirst);
      color: var(--lightColor);

      @media (max-width: 3839px) {
        width: 68%;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }

      span {
        display: inline-block;
        width: 90px;
        height: 90px;
        background-color: rgba(250, 250, 250, 0.2);
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 58px 58px;
        vertical-align: middle;

        @media (max-width: 3839px) {
          width: 60px;
          height: 60px;
          background-size: 39px 39px;
        }

        @media (max-width: 2559px) {
          width: 44px;
          height: 44px;
          background-size: 28px 28px;
        }

        @media (max-width: 1919px) {
          width: 30px;
          height: 30px;
          background-size: 20px 20px;
        }

        @media (max-width: 1365px) {
          width: 24px;
          height: 24px;
          background-size: 16px 16px;
        }

        @media (max-width: 767px) {
          width: 20px;
          height: 20px;
          background-size: 13px 13px;
        }
      }
    }
  }

  .fleetContent {
    height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    gap: 160px;
    transform: translateX(100%);
    cursor: none;
    margin-left: -80%;

    @media (max-width: 3839px) {
      gap: 100px;
    }

    @media (max-width: 2559px) {
      gap: 64px;
    }

    @media (max-width: 1919px) {
      gap: 30px;
      margin-left: -70%;
    }

    @media (max-width: 1365px) {
      margin-left: -90%;
    }

    @media (max-width: 767px) {
      gap: 40px;
      margin-left: -100%;
    }

    .articleFleet {
      width: 1125px;
      height: 1700px;
      background-color: var(--darkPrimaryColor);
      border-radius: 60px;
      overflow: hidden;
      isolation: isolate;
      cursor: none;

      @media (max-width: 3839px) {
        width: 730px;
        height: 1080px;
        border-radius: 40px;
      }

      @media (max-width: 2559px) {
        width: 542px;
        height: 800px;
        border-radius: 30px;
      }

      @media (max-width: 1919px) {
        width: 400px;
        height: 585px;
        border-radius: 20px;
      }

      @media (max-width: 767px) {
        width: 330px;
        height: 475px;
      }

      &:hover .articleTop img {
        @media (min-width: 1365px) {
          transform: scale(1.05);
        }
      }

      .articleTop {
        height: 1198.51px;
        overflow: hidden;

        @media (max-width: 3839px) {
          height: 776.42px;
        }
        @media (max-width: 2559px) {
          height: 577.41px;
        }
        @media (max-width: 1919px) {
          height: 426.14px;
        }
        @media (max-width: 767px) {
          height: 349.43px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: transform 0.3s ease-in-out;
        }
      }

      .articleBottom {
        height: 30%;
        padding: 80px;
        padding-bottom: 64px;

        @media (max-width: 3839px) {
          padding: 40px;
        }

        @media (max-width: 2559px) {
          padding: 28px;
        }

        @media (max-width: 1919px) {
          padding: 20px;
        }

        @media (max-width: 767px) {
          padding: 16px;
        }

        h5 {
          margin-bottom: 80px;
          font: var(--titleFourth);
          color: var(--lightColor);

          @media (max-width: 3839px) {
            margin-bottom: 40px;
          }

          @media (max-width: 2559px) {
            margin-bottom: 28px;
          }

          @media (max-width: 1919px) {
            margin-bottom: 20px;
          }

          @media (max-width: 767px) {
            margin-bottom: 16px;
          }
        }

        ul {
          padding-top: 60px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid rgba(250, 250, 250, 0.2);

          @media (max-width: 3839px) {
            padding-top: 40px;
          }

          @media (max-width: 2559px) {
            padding-top: 28px;
          }

          @media (max-width: 1919px) {
            padding-top: 20px;
          }

          @media (max-width: 767px) {
            padding-top: 16px;
          }

          li {
            span {
              font: var(--titleSixth);
              color: var(--lightColor);
            }

            p {
              font: var(--captionThird);
              color: var(--lightColor);
              opacity: 0.5;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .articleFleetLast {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1125px;
      height: 1700px;
      background-color: var(--darkPrimaryColor);
      border-radius: 60px;
      cursor: none;

      @media (max-width: 3839px) {
        width: 730px;
        height: 1080px;
        border-radius: 40px;
      }

      @media (max-width: 2559px) {
        width: 542px;
        height: 800px;
        border-radius: 30px;
      }

      @media (max-width: 1919px) {
        width: 400px;
        height: 585px;
        border-radius: 20px;
      }

      @media (max-width: 767px) {
        width: 330px;
        height: 475px;
      }

      &:hover div {
        @media (min-width: 1365px) {
          transform: scale(1.1);
        }
      }

      div {
        width: 600px;
        height: 600px;
        background-color: rgba(250, 250, 250, 0.1);
        border-radius: 50%;
        background-image: url("../../../public/content/images/about/fleet/vessel-white-icon.svg");
        background-size: 388px 388px;
        background-repeat: no-repeat;
        background-position: center;
        transition: transform 0.3s ease-in-out;

        @media (max-width: 3839px) {
          width: 400px;
          height: 400px;
          background-size: 258px 258px;
        }

        @media (max-width: 2559px) {
          width: 280px;
          height: 280px;
          background-size: 180px 180px;
        }

        @media (max-width: 1919px) {
          width: 200px;
          height: 200px;
          background-size: 130px 130px;
        }

        @media (max-width: 767px) {
          width: 150px;
          height: 150px;
          background-size: 97px 97px;
        }
      }

      button {
        display: none;

        @media (max-width: 1365px) {
          left: 50%;
          bottom: 43px;
          position: absolute;
          display: block;
          padding: 8px 44px 8px 16px;
          opacity: 1;
          transform: translateX(-50%);
          font: var(--titleFifth);
          border: 1px solid var(--lightColor);
          border-radius: 100000px;
          color: #fff;
          background-image: url("../../../public/content/icons/arrow-right-white.svg");
          background-repeat: no-repeat;
          background-position: 90% 50%;
          background-size: 24px 24px;
        }
        @media (max-width: 767px) {
          bottom: 32px;
        }
      }
    }
  }
}

.services {
  display: flex;
  padding-top: 400px;
  padding-bottom: 400px;
  cursor: none;

  @media (max-width: 3839px) {
    padding-top: 250px;
    padding-bottom: 250px;
  }

  @media (max-width: 2559px) {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  @media (max-width: 1919px) {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  @media (max-width: 1365px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.servicesLeft {
  width: 30%;

  @media (max-width: 1365px) {
    width: 100%;
  }

  h2 {
    font: var(--titleThird);
    color: var(--accentColor);

    @media (max-width: 1365px) {
      margin-bottom: 27px;
    }
  }
}

.servicesRight {
  width: 70%;

  @media (max-width: 1365px) {
    width: 100%;
  }

  ul {
    li {
      position: relative;
      padding-top: 85px;
      padding-bottom: 85px;
      border-top: 1px solid rgba(250, 250, 250, 0.2);

      @media (max-width: 3839px) {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      @media (max-width: 2559px) {
        padding-top: 34px;
        padding-bottom: 34px;
      }

      @media (max-width: 1919px) {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      @media (max-width: 1365px) {
        padding-top: 32px;
        padding-bottom: 32px;
      }

      &:last-child {
        border-bottom: 1px solid rgba(250, 250, 250, 0.2);
      }

      &:hover span {
        transform: scale3d(1.025, 1.025, 1.025);
        color: var(--accentColor);
      }

      span {
        display: inline-block;
        width: 100%;
        font: var(--titleSecond);
        color: var(--lightColor);
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
        pointer-events: none;

        @media (max-width: 1365px) {
          margin-bottom: 80px;
        }

        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }

      img {
        position: absolute;
        display: inline-block;
        top: -25%;
        right: 20%;
        width: 759px;
        height: 537px;
        border-radius: 60px;
        transform: scale3d(1, 1.3, 1);
        opacity: 0;
        transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out, top 0.5s ease-in-out, right 0.5s ease-in-out;
        will-change: transform;
        pointer-events: none;
        z-index: 10;

        @media (max-width: 3839px) {
          width: 494.69px;
          height: 350px;
          border-radius: 50px;
        }

        @media (max-width: 2559px) {
          width: 395.75px;
          height: 280px;
          border-radius: 40px;
        }

        @media (max-width: 1919px) {
          width: 254.41px;
          height: 180px;
          border-radius: 30px;
        }

        @media (max-width: 1365px) {
          top: 34px;
          right: 0;
          width: 250px;
          height: 180px;
          transform: scale3d(1, 1, 1);
          opacity: 1;
          border-radius: 20px;
        }

        @media (max-width: 767px) {
          top: 35px;
          width: 165px;
          height: 115px;
          border-radius: 10px;
        }
      }

      button {
        display: none;

        @media (max-width: 1365px) {
          display: block;
          padding: 8px 44px 8px 16px;
          font: var(--titleFifth);
          border: 1px solid var(--lightColor);
          color: #fff;
          background-image: url("../../../public/content/icons/arrow-right-white.svg");
          background-repeat: no-repeat;
          border-radius: 100000px;
          background-position: 90% 50%;
          background-size: 24px 24px;
        }

        @media (max-width: 767px) {
          background-size: 20px 20px;
        }
      }
    }
  }
}
