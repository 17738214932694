.container {
  display: block;
  position: relative;
  width: 700px;
  height: 700px;

  @media (max-width: 3839px) {
    width: 450px;
    height: 450px;
  }

  @media (max-width: 2559px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 1919px) {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 1365px) {
    width: 180px;
    height: 180px;
  }
}

.text {
  width: 100%;
  height: 100%;
  animation: textRotate 25s normal linear infinite;
}

.image {
  position: absolute;
  bottom: 200px;
  left: 190px;
  width: 367px;
  height: 268px;

  @media (max-width: 3839px) {
    width: 236px;
    height: 172px;
    bottom: 130px;
    left: 130px;
  }

  @media (max-width: 2559px) {
    width: 158px;
    height: 115px;
    bottom: 85px;
    left: 85px;
  }

  @media (max-width: 1919px) {
    width: 105px;
    height: 77px;
    bottom: 60px;
    left: 50px;
  }

  @media (max-width: 1365px) {
    width: 95px;
    height: 70px;
    bottom: 54px;
    left: 50px;
  }
}

@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg);
  }
}
